import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ResponsiveAppBar from './components/AppBar';
import Footer from './components/Footer';
import reportWebVitals from './reportWebVitals';
import Form from './components/Form';
// import About from './components/About';
import AboutUs from './components/AboutUs';
import FAQ from './components/FAQ';
import LoanCalculator from './components/LoanCalculator';
import PrivacyPolicy from './components/PrivacyPolicy'

import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";

import FixFlipLoan from './components/FixFlipLoan';
import NewConstruction from './components/NewConstruction';
import CashOutRefinance from './components/CashOutRefinance';
import RentalProperty from './components/RentalProperty';
import CommercialHard from './components/CommercialHard';
import BridgeLoan from './components/BridgeLoan';
import SecondMortgage from './components/SecendMortgages';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <ResponsiveAppBar></ResponsiveAppBar>
      
      <Routes>
          <Route index element={<App />}/>
          <Route path="apply" element={<Form />}/>
          <Route path="about">
            <Route index element={<AboutUs/>}></Route>
            <Route path="fixflip" element={ <FixFlipLoan/> }></Route>
            <Route path="construction" element={ <NewConstruction/> }></Route>
            <Route path="refinance" element={ <CashOutRefinance/>}></Route>
            <Route path="rental" element={<RentalProperty/>}></Route>
            <Route path="commercial" element={<CommercialHard/>}></Route>
            <Route path="bridge" element={<BridgeLoan/>}></Route>
            <Route path="mortgage" element={<SecondMortgage/>}></Route>
          </Route>
          <Route path="calculate" element={<LoanCalculator/>}/>
          <Route path="faq" elemen={<FAQ/>}/>
          <Route path="privacypolicy" element={<PrivacyPolicy/>}/>
      </Routes>

      <Footer/>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
