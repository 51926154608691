import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
// import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';

import { useNavigate } from "react-router-dom";

const logoStyle = {
  width: '140px',
  height: 'auto',
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="/" // change to / for production
      >MK Lending&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
    const navigate = useNavigate();

    function handleClick(){
        navigate('/apply');
    }

    return (
        <Container
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 4, sm: 8 },
            py: { xs: 8, sm: 10 },
            textAlign: { sm: 'center', md: 'left' },
            marginTop: '10px',
            color: 'black',
            fontFamily: 'sans-serif', 
            letterSpacing: '.3rem',
            width: '100%'
        }}
        >
        <Box
            sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            justifyContent: 'space-between',
            }}
        >
            <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                minWidth: { xs: '100%', sm: '60%' },
            }}
            >
            <Box sx={{ width: { xs: '100%', sm: '60%' }}}>
                <Typography variant="h4" fontWeight={600} gutterBottom fontFamily={'sans-serif'} color={'#000000'}>
                MK Lending
                </Typography>
                
                <Typography variant="body2" fontWeight={600} gutterBottom fontFamily={'sans-serif'} color={'#000000'}>
                Start your application
                </Typography>
                <Typography variant="body2" color="text.secondary" mb={2} fontFamily={'sans-serif'}>
                Get qualified in as little as a day!
                </Typography>
                <Button  sx={{border: '2px solid #000000', flexShrink: 0, color:"white", background:'#000000', fontWeight: 'bold', textShadow: '0px 0px 4px #000000'}} fontFamily={'sans-serif'} onClick={handleClick}>
                    Get Approved Today!
                </Button>
            </Box>
            </Box>
        
            <Box
            sx={{
                display: { xs: 'none', sm: 'flex' },
                flexDirection: 'column',
                gap: 1,
                marginRight: "200px",
            }}
            >
            <Typography variant="body2" fontWeight={600}>
                Legal
            </Typography>
            <Link color="text.secondary" href="/privacypolicy">
                Privacy Policy
            </Link>
            <Link color="text.secondary" href="/apply">
                Contact
            </Link>
            </Box>
        </Box>
        <Box
            sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: { xs: 4, sm: 8 },
            width: '100%',
            borderTop: '1px solid',
            borderColor: 'divider',
            }}
        >
            <div>
            <Link color="text.secondary" href="/privacypolicy">
                Privacy Policy
            </Link>
            {/* <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
                &nbsp;•&nbsp;
            </Typography>
            <Link color="text.secondary" href="#">
                Terms of Service
            </Link> */}
            <Copyright />
            </div>
            <Stack
            direction="row"
            justifyContent="left"
            spacing={1}
            useFlexGap
            sx={{
                color: 'text.secondary',
            }}
            >
            <IconButton
                color="inherit"
                href="https://github.com/"
                aria-label="GitHub"
                sx={{ alignSelf: 'center' }}
            >
                <FacebookIcon />
            </IconButton>
            <IconButton
                color="inherit"
                href="https://twitter.com/"
                aria-label="X"
                sx={{ alignSelf: 'center' }}
            >
                <TwitterIcon />
            </IconButton>
            <IconButton
                color="inherit"
                href="https://www.linkedin.com/"
                aria-label="LinkedIn"
                sx={{ alignSelf: 'center' }}
            >
                <LinkedInIcon />
            </IconButton>
            </Stack>
        </Box>
        </Container>
  );
}