import React from "react";

import "./App.css";

import { Box, Grid, Typography, Card, Button, CardContent, CardActions, CardMedia } from "@mui/material";
import { Link } from 'react-router-dom';

// change for production
import about1 from './static/about1.png';
import about2 from './static/about2.png';
import about3 from './static/about3.png';
import about4 from './static/about4.png';
import about5 from './static/about5.png';
import about6 from './static/about6.png';
import about7 from './static/about7.png';
import homepage from './static/frontpage3.jpg';
import homepage2 from './static/homepage2.jpg';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function App() {
  // const [message, setMessage] = useState("");

  // useEffect(() => {
  //   getMessage();
  // }, []);

  // async function getMessage() {
  //   const result = await fetch("/api/message");
  //   const json = await result.json();

  //   setMessage(json);
  // }
  


  return (<>
    <div className="App">
      
        <Box sx={{ flexGrow: 1, position: 'relative'}}>
          <Grid container spacing={2} sx={{}}>
            <Grid item sm={12} sx={{ display: {sm: 'none', md: 'block'}, height: {md: '500px'}, backgroundImage:"url('"+homepage+"')", backgroundSize:{lg: "100% 800px", md: '130% 800px'},backgroundRepeat: 'no-repeat', backgroundAttachment: 'contain', backgroundPositionX:{lg: '0px', md: '-200px'}}}>
              <Box  sx={{width: '100%', marginTop: '12%', color: '#fafafa', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
                {/* <Card raised sx={{flexDirection: 'column', justifyContent: 'space-between', marginTop: "40px", width: "430px", display: {xs: 'none', sm: 'block'}, height: '380px'}}>
                    <CardContent>
                      <Typography variant='body1' sx={{ marginTop:'10px', fontFamily: 'sans-serif', fontWeight: 'bold'}}> As a
                          premier hard money lender, we specialize in providing swift and flexible financial
                          solutions for real estate investors. Our commitment is to empower clients with the
                          capital they need to seize lucrative opportunities in the dynamic real estate market.
                          With a focus on efficiency, transparency, and personalized service, MK Lending
                          stands as the go-to source for reliable hard money lending, propelling your real estate
                          ventures to new heights.
                      </Typography>
                    </CardContent>
                    <CardActions sx={{display: 'flex', justifyContent: 'space-evenly', marginBottom: '6px'}}>
                      <Link to="/apply">
                        <Button size="large" sx={{width: '190px', outline: 'none', border: '2px solid #000000',  color: 'white', background: '#000000', textShadow:'0px 0px 1px white',fontWeight: 'bold', '&:hover': {textShadow:'0px 0px 4px #000000'}}}>Apply Today!</Button>
                      </Link>
                      <Link to="/about">
                        <Button size="large" sx={{width: '190px', outline: 'none', border: '2px solid #000000', color: '#000000', background: 'white',  fontWeight: 'bold'}}>About Us</Button>
                      </Link>
                    </CardActions>
                    
                  </Card> */}
                  <Typography variant='h5' sx={{ textAlign: 'left', display: {xs: 'none', sm: 'block'}, textShadow: '0px 0px 10px #000000',fontWeight: 'bold', fontFamily: 'sans-serif', letterSpacing: '.3rem', marginLeft: '50px'}} >
                    Loans Starting at $50,0000
                  </Typography>
                  <Typography variant='h2' sx={{ textAlign: 'left', display: {xs: 'none', sm: 'block'}, textShadow: '0px 0px 10px #000000',fontWeight: 'bold', fontFamily: 'sans-serif', letterSpacing: '.3rem', marginLeft: '50px'}} >
                    Fast Money
                  </Typography>
                  <Typography variant='h3' sx={{ textAlign: 'left', display: {xs: 'none', sm: 'block'}, textShadow: '0px 0px 10px #000000',fontWeight: 'bold', fontFamily: 'sans-serif', letterSpacing: '.3rem', marginLeft: '50px'}} >
                    For You
                  </Typography>
              </Box>


              
              {/* <Card raised sx={{marginTop: "60px", width: "30%", marginLeft: '40px', }}>
                <CardContent>
                  <Typography variant='h5' sx={{ marginTop: '5px'}}>
                    [Your Text]
                  </Typography> 
                </CardContent>
                <CardActions sx={{justifyContent: 'center'}}> 
                  <Link to="/apply">
                    <Button size="large" sx={{border: '2px solid #000000', color: 'white', background: '#000000', textShadow:'0px 0px 4px #000000', fontWeight: 'bold'}}>Apply Today</Button>
                  </Link>
                </CardActions>
                
              </Card> */}
            </Grid>
            
           {/* <Grid item xs={12} sx={{justifyContent: 'center', alignContent: 'center', marginTop: '10px'}}>
              <Typography variant="h3" fontWeight={'bold'} component="div" fontFamily={'sans-serif'} color={'#000000'}>
                Our Services
              </Typography>
           </Grid> */}

          

           <Grid item md={6} lg={3} sm={12} sx={{display: 'flex', flexDirection: 'row', padding: '10px', alignItems: "center", justifyContent: "space-evenly"}}>
                  <Card raised sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', overflow: "auto", height: '400px', maxWidth:"300px"}}>
                    <CardMedia
                          component={'img'}
                          image={about2} // fix for production
                          sx={{objectFit: 'contain', height: '50%', width: '50%', marginTop: '10px'}}
                      />  
                    <CardContent sx={{paddingTop: '0px'}}>
                      <Typography gutterBottom variant="h5" fontWeight='bold'  component="div" fontFamily={'sans-serif'} color={'#000000'}>
                        New Contstruction
                      </Typography>
                      <Typography variant="body" fontWeight='bold' color="text.secondary" >
                        Funds for purchase + rehab or refinance + rehab
                      </Typography>
                      {/* <Typography variant="body2" color="text.secondary" marginTop="10px">
                        Loan Size: $100,0000 - $5 million
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Interest Rate: 9%-13%
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Origination Points: 0% - 2%
                      </Typography> */}
                    </CardContent>
                    <CardActions sx={{justifyContent: 'center'}}>
                      {/* <Link to="/apply">
                        <Button size="medium" sx={{border: '2px solid #000000',color: 'white', fontWeight: 'bold', background: '#000000', textShadow:'0px 0px 4px #000000', }}>Apply Today</Button>
                      </Link> */}
                      <Link to='/about/construction'>
                        <Button size="medium" sx={{color: '#000000', fontWeight: 'bold', border: '2px solid #000000'}}>Learn More</Button>
                      </Link>
                    </CardActions>
                  </Card>
            </Grid>

           <Grid item md={6} sm={12} lg={3} sx={{display: 'flex', flexDirection: 'row', padding: '10px', alignItems: "center", justifyContent: "space-evenly"}}>
              <Card raised sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center',overflow: "auto", height: '400px', maxWidth:"300px"}}>
                    <CardMedia
                        component={'img'}
                        image={about4} // fix for production
                        sx={{objectFit: 'contain', height: '50%', width: '50%', marginTop: '10px'}}
                    />  
                    <CardContent sx={{paddingTop: '0px'}}>
                      <Typography gutterBottom variant="h5" fontWeight='bold'  component="div" fontFamily={'sans-serif'} color={'#000000'}>
                        Rental Property
                      </Typography>
                      <Typography variant="body" fontWeight='bold' color="text.secondary" >
                        Funds to purchase, rehab, rent, and refinance a property
                      </Typography>
                      {/* <Typography variant="body2" color="text.secondary" marginTop="10px">
                        Loan Size: $100,0000 - $5 million
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Interest Rate: 9%-13%
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Origination Points: 0% - 2%
                      </Typography> */}
                    </CardContent>
                    <CardActions sx={{justifyContent: 'center'}}>
                      {/* <Link to="/apply">
                        <Button size="medium" sx={{border: '2px solid #000000',color: 'white', fontWeight: 'bold', background: '#000000', textShadow:'0px 0px 4px #000000', }}>Apply Today</Button>
                      </Link> */}
                      <Link to='/about/rental'>
                        <Button size="medium" sx={{color: '#000000', fontWeight: 'bold', border: '2px solid #000000'}}>Learn More</Button>
                      </Link>
                    </CardActions>
                  </Card>
            </Grid>   

           <Grid item md={6} lg={3} sm={12} sx={{display: 'flex', flexDirection: 'row', padding: '10px', alignItems: "center", justifyContent: "space-evenly"}}>
              <Card raised sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center',overflow: "auto", height: '400px', maxWidth:"300px"}}>
                <CardMedia
                      component={'img'}
                      image={about1} // fix for production
                      sx={{objectFit: 'contain', height: '50%', width: '50%', marginTop: '10px'}}
                  />  
                <CardContent sx={{paddingTop: '0px'}}>
                  <Typography gutterBottom variant="h5" fontWeight={'bold'} component="div" fontFamily={'sans-serif'} color={'#000000'}>
                    Fix & Flip
                  </Typography>
                  <Typography variant="body" fontWeight='bold' color="text.secondary" >
                    Funds for purchase + rehab or refinance + rehab
                  </Typography>
                  {/* <Typography variant="body2" color="text.secondary" marginTop="10px">
                    Loan Size: $100,0000 - $5 million
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Interest Rate: 9%-13%
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Origination Points: 0% - 2%
                  </Typography> */}
                </CardContent>
                <CardActions sx={{justifyContent: 'center'}}>
                  {/* <Link to="/apply">
                    <Button size="medium" sx={{border: '2px solid #000000',color: 'white', fontWeight: 'bold', background: '#000000', textShadow:'0px 0px 4px #000000', }}>Apply Today</Button>
                  </Link> */}
                  <Link to="/about/fixflip">
                    <Button size="medium" sx={{borderRadius: '2px solid #000000',color: '#000000', fontWeight: 'bold', border: '2px solid #000000'}}>Learn More</Button>
                  </Link>
                </CardActions>
              </Card>
          </Grid>     

           <Grid item md={6} lg={3} sm={12} sx={{display: 'flex', flexDirection: 'row', padding: '10px', alignItems: "center", justifyContent: "space-evenly"}}>
                  <Card raised sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center',overflow: "auto", height: '400px', maxWidth:"300px"}}>
                    <CardMedia
                          component={'img'}
                          image={about6} // fix for production
                          sx={{objectFit: 'contain', height: '50%', width: '50%', marginTop: '10px'}}
                      />  
                    <CardContent sx={{paddingTop: '0px'}}>
                      <Typography gutterBottom variant="h5" fontWeight='bold'  component="div" fontFamily={'sans-serif'} color={'#000000'}>
                        Bridge Loans
                      </Typography>
                      <Typography variant="body" fontWeight='bold' color="text.secondary" >
                        Funds to purchase, rehab, rent, and refinance a property
                      </Typography>
                      {/* <Typography variant="body2" color="text.secondary" marginTop="10px">
                        Loan Size: $100,0000 - $5 million
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Interest Rate: 9%-13%
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Origination Points: 0% - 2%
                      </Typography> */}
                    </CardContent>
                    <CardActions sx={{justifyContent: 'center'}}>
                      {/* <Link to="/apply">
                        <Button size="medium" sx={{border: '2px solid #000000',color: 'white', fontWeight: 'bold', background: '#000000', textShadow:'0px 0px 4px #000000'}}>Apply Today</Button>
                      </Link> */}
                      <Link to='/about/bridge'>
                        <Button size="medium" sx={{color: '#000000', fontWeight: 'bold', border: '2px solid #000000'}}>Learn More</Button>
                      </Link>
                    </CardActions>
                  </Card>
            </Grid>

            

           

            

            <Grid item md={6} lg={4} sm={12} sx={{ display: 'flex', flexDirection: 'row', padding: '10px', alignItems: "center", justifyContent: "right"}}>
                <Card raised sx={{overflow: "auto", height: '400px', maxWidth:"320px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
                    <CardMedia
                      component={'img'}
                      image={about3} // fix for production
                      sx={{objectFit: 'contain', height: '50%', width: '50%', marginTop: '10px'}}
                    />
                    <CardContent sx={{paddingTop: '0px'}}>
                      <Typography gutterBottom variant="h5" fontWeight='bold' component="div" fontFamily={'sans-serif'} color={'#000000'}>
                        Cash Out Refinance
                      </Typography>
                      <Typography variant="body" fontWeight='bold' color="text.secondary" >
                        [Your Text]
                       </Typography>
                      {/*<Typography variant="body2" color="text.secondary" marginTop="10px">
                        Loan Size: $100,0000 - $5 million
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Interest Rate: 9%-13%
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Origination Points: 0% - 2%
                      </Typography> */}
                    </CardContent>
                    <CardActions sx={{justifyContent: 'center'}}>
                        {/* <Link to="/apply">
                          <Button size="medium" sx={{border: '2px solid #000000',color: 'white', fontWeight: 'bold', background: '#000000', textShadow:'0px 0px 4px #000000', }}>Apply Today</Button>
                        </Link>   */}
                        <Link to='/about/refinance'>
                          <Button size="medium" sx={{borderRadius: '2px solid #000000',color: '#000000', fontWeight: 'bold', border: '2px solid #000000'}}>Learn More</Button>
                        </Link>                    
                    </CardActions>
                  </Card>
           </Grid>

           <Grid item md={6} lg={4} sm={12} sx={{display: 'flex', flexDirection: 'row', padding: '10px', alignItems: "center", justifyContent: "center"}}>
              <Card raised sx={{overflow: "auto", height: '400px', maxWidth:"320px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
                    <CardMedia
                        component={'img'}
                        image={about5} // fix for production
                        sx={{objectFit: 'contain', height: '50%', width: '50%', marginTop: '10px'}}
                    />  
                    <CardContent sx={{paddingTop: '0px'}}>
                      <Typography gutterBottom variant="h5" fontWeight='bold'  component="div" fontFamily={'sans-serif'} color={'#000000'}>
                        Commercial Hard Money
                      </Typography>
                      <Typography variant="body" fontWeight='bold' color="text.secondary" >
                        [Your Text]
                      </Typography>
                      {/* <Typography variant="body2" color="text.secondary" marginTop="10px">
                        Loan Size: $100,0000 - $5 million
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Interest Rate: 9%-13%
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Origination Points: 0% - 2%
                      </Typography> */}
                    </CardContent>
                    <CardActions sx={{justifyContent: 'center'}}>
                      {/* <Link to="/apply">
                        <Button size="medium" sx={{border: '2px solid #000000',color: 'white', fontWeight: 'bold', background: '#000000', textShadow:'0px 0px 4px #000000', }}>Apply Today</Button>
                      </Link> */}
                      <Link to="/about/commercial">
                        <Button size="medium" sx={{color: '#000000', fontWeight: 'bold', border: '2px solid #000000'}}>Learn More</Button>
                      </Link>
                    
                    </CardActions>
                  </Card>
           </Grid>  
                    

            <Grid item md={6} lg={4} sm={12} sx={{display: 'flex', flexDirection: 'row', padding: '10px', alignItems: "center", justifyContent: "left"}}>
                  <Card raised sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center',overflow: "auto", height: '400px', maxWidth:"320px"}}>
                    <CardMedia
                          component={'img'}
                          image={about7} // fix for production
                          sx={{objectFit: 'contain', height: '50%', width: '50%', marginTop: '10px'}}
                      />  
                    <CardContent sx={{paddingTop: '0px'}}>
                      <Typography gutterBottom variant="h5" fontWeight='bold'  component="div" fontFamily={'sans-serif'} color={'#000000'}>
                      Second Mortgages
                      </Typography>
                      <Typography variant="body" fontWeight='bold' color="text.secondary" >
                        Funds to bridge the gap between the purchase of a new property and the sale of an existing one
                      </Typography>
                      {/* <Typography variant="body2" color="text.secondary" marginTop="10px">
                        Loan Size: $100,0000 - $5 million
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Interest Rate: 9%-13%
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Origination Points: 0% - 2%
                      </Typography> */}
                    </CardContent>
                    <CardActions sx={{justifyContent: 'center'}}>
                      {/* <Link to="/apply">
                        <Button size="medium" sx={{border: '2px solid #000000',color: 'white', fontWeight: 'bold', background: '#000000', textShadow:'0px 0px 4px #000000'}}>Apply Today</Button>
                      </Link> */}
                      <Link to='/about/mortgage'>
                        <Button size="medium" sx={{color: '#000000', fontWeight: 'bold', border: '2px solid #000000'}}>Learn More</Button>
                      </Link>
                    </CardActions>
                  </Card>
            </Grid>

            {/* <Grid item sm={6} md={3} xs={12} sx={{display: 'flex', flexDirection: 'column', padding: '10px', alignItems: "center", justifyContent: "space-evenly"}}>
                  <Link to="/apply">
                    <Button size="medium" sx={{marginTop: '20px', marginBottom: '15px',border: '2px solid #000000',color: 'black', fontWeight: 'bold', background: '#ffffff', borderRadius: '50%',  height: '120px',width: '120px', textShadow:'0px 0px 4px #000000'}}>
                            <ArrowForwardIcon sx={{transform: 'scale(2)', '&:hover':{color: '#000000'}}}></ArrowForwardIcon>
                    </Button>
                  </Link>
                 
                  <Typography gutterBottom variant="h5"  fontWeight={'bold'} component="div" fontFamily={'sans-serif'} width={'60%'}>
                      Apply Today!
                  </Typography>
                  
                  <Card raised sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center',overflow: "auto", height: '350px', maxWidth:"300px"}}>
                    <CardActions sx={{justifyContent: 'center'}}>
                      <Link to="/about">
                        
                      </Link>
                    </CardActions>
                    <CardContent sx={{paddingTop: '0px'}}>
                      
                    </CardContent>
                  </Card> 
            </Grid> */}
            
            <Grid item md={12} sx={{marginTop: '20px', display: {xs: 'none', xs: 'none', md: 'flex'}, height: '550px', alignItems: 'center', justifyContent: 'center', backgroundImage: "url('"+homepage2+"')", backgroundAttachment:'contain', backgroundSize:'100%', backgroundRepeat: 'no-repeat', backgroundPositionY: '-250px'}}>
                <Card raised sx={{flexDirection: 'column', justifyContent: 'space-between', alignItems:'center', display: 'flex', height: '340px', width: '47%'}}>
                  <CardContent> 
                    <Typography variant='h3' sx={{fontFamily: 'sans-serif', color: '#000000', fontWeight: 'bold', marginBottom: '10px'}}>Don't see your loan?</Typography>
                    <Typography variant='h4' sx={{fontFamily: 'sans-serif', color: 'gray', fontWeight: 'bold', marginBottom: '20px'}}>No problem!</Typography>
                    <Typography variant='body1' sx={{ fontFamily: 'sans-serif'}}>[Your Text]</Typography>
                  </CardContent>
                  <CardActions sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', marginBottom: '10px'}}>
                    <Link to='/apply'>
                      <Button size='large' sx={{width: '200px', border: '2px solid #000000',  color: 'white', background: '#000000', textShadow:'0px 0px 1px white',fontWeight: 'bold', '&:hover': {textShadow:'0px 0px 4px #000000'}}}>Apply Today!</Button>
                    </Link>
                    <Link to='/about'>
                      <Button size='large' sx={{width: '200px', color: '#000000', fontWeight: 'bold', border: '2px solid #000000'}}>Learn More</Button>
                    </Link>
                  </CardActions>
                </Card>
            </Grid>

          </Grid>
        </Box>
    </div>
    </>
  );
}

export default App;
