import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';

import { useNavigate } from "react-router-dom";
import styled from '@emotion/styled';

const pages = ['FAQ', 'Loan Calculator','About Us', 'Apply Now' ];
const pageLinks = ['/faq','/calculate','/about', '/apply'];

const loans = [
  'Fix and Flip', 'New Construction', 'Cash Out Refinance', 'Rental Property',
  'Commercial Hard Money', 'Bridge Loans', 'Second Mortgages'
];
const loanLinks = [
  '/about/fixflip', '/about/construction', '/about/refinance', '/about/rental', 
  '/about/commercial', '/about/bridge', '/about/mortgage'
];

function ResponsiveAppBar() {
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElLoans, setAnchorElLoans] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenLoanMenu = (event) => {
    setAnchorElLoans(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseLoanNav = () => {
    setAnchorElLoans(null);
  }

  return (
    <AppBar position="sticky" sx={{backgroundColor: '#ffffff', color: '#000000', height: '80px', justifyContent: 'center'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AssuredWorkloadIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/" // for development, change to / for production
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'sans-serif',
              fontWeight: 600,
              letterSpacing: '.2rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Hard Money Marketplace
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, i) => (
                <MenuItem key={page} onClick={(e) => {handleCloseNavMenu(); navigate(pageLinks[i])}}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/" // for development, change to / for production
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'sans-serif',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            MK Lending
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent:'right',}}>
            <Button
              key={'Loan Types'}
              onClick={(e) => {handleOpenLoanMenu(e);}}
              sx={{my: 2,  mr: 2, color: 'black',  background: '#ffffff', display: 'block', fontWeight: '600', '&:hover':{color: 'blue', background: 'white'}}}
            >
              Loan Types
            </Button>
            <Menu 
              id='loan-menu'
              anchorEl={anchorElLoans}
              anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
              open={Boolean(anchorElLoans)}
              onClose={handleCloseLoanNav}
              keepMounted
              sx={{
                transform: 'translateX(0px) translateY(20px)',
                borderTopLeftRadius: '0px !important'
              }}
            >
              {loans.map((loan, i) => (
                <MenuItem 
                  key={loan} 
                  onClick={(e) => {handleCloseLoanNav(); navigate(loanLinks[i]);}}
                >
                  <Typography textAlign="center">{loan}</Typography>
                </MenuItem>
              ))}
            </Menu>

            <Button
                key={pages[0]}
                onClick={(e) => {handleCloseNavMenu(); navigate(pageLinks[0]);}}
                sx={{ my: 2, mr: 2,  color: 'black',  background: '#ffffff', display: 'block', fontWeight: '600', '&:hover':{color: 'blue', background: 'white'}}}
            >
              {pages[0]}
            </Button>
            
            <Button
                key={pages[1]}
                onClick={(e) => {handleCloseNavMenu(); navigate(pageLinks[1]);}}
                sx={{ my: 2, mr: 2,  color: 'black',  background: '#ffffff', display: 'block', fontWeight: '600', '&:hover':{color: 'blue', background: 'white'}}}
            >
              {pages[1]}
            </Button>

            <Button
                key={pages[2]}
                onClick={(e) => {handleCloseNavMenu(); navigate(pageLinks[2]);}}
                sx={{ my: 2, mr: 2,  color: 'black',  background: '#ffffff', display: 'block', fontWeight: '600', '&:hover':{color: 'blue', background: 'white'}}}
            >
              {pages[2]}
            </Button>
                    
            <Button
                key={pages[3]}
                onClick={(e) => {handleCloseNavMenu();  navigate(pageLinks[3]);}}
                sx={{ my: 2, color: 'white',  background: 'blue', display: 'block', textShadow:'0px 0px 8px blue', border: '2px solid blue', fontWeight: '600'}}
            >
                {pages[3]}
            </Button>
            
          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;