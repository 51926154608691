import {useState} from 'react';

import { Container, Select, Card, CardActionArea, CardContent, CardActions, Typography, Button, TextField, Box, FormControlLabel, FormGroup, InputAdornment, InputLabel, RadioGroup, Radio, MenuItem} from '@mui/material';
import { useNavigate } from "react-router-dom";

const LoanPurpose = Object.freeze({
    EMPTY: 'empty',
    REFINANCE: 'refinance',
    PURCHASE: 'purchase'
})

const IsPrimaryResidence = Object.freeze({
    EMPTY: 'empty',
    YES: 'yes', 
    NO: 'no',
})

export default function Form (){
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    // const [preferredName, setPreferredName] = useState("");
    const [addressOne, setAddressOne] = useState("");
    const [addressTwo, setAddressTwo] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [country, setCountry] = useState("United States");

    const [loanPurpose, setLoanPurpose] = useState(LoanPurpose.EMPTY);
    const [isPrimaryResidence, setIsPrimaryResidence] = useState(IsPrimaryResidence.EMPTY)

    // const [companyName, setCompanyName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    // const [creditScore, setCreditScore] = useState("");
    const [loanAmount, setLoanAmount] = useState("");
    const [notes, setNotes] = useState("");


    function changeFirstName(event){
        setFirstName(event.target.value);
    }

    function changeLastName(event){
        setLastName(event.target.value);
    }

    // function changePreferredName(event) {
    //     setPreferredName(event.target.value);
    // }

    // function changeCompanyName(event) {
    //     setCompanyName(event.target.value);
    // }

    function changeAddressOne(event){
        setAddressOne(event.target.value);
    }
    function changeAddressTwo(event){
        setAddressTwo(event.target.value);
    }
    function changeCity(event){
        setCity(event.target.value);
    }
    function changeState(event) {
        setState(event.target.value);
    }
    function changeZipCode(event){
        setZipCode(event.target.value);
    }
    // function changeCountry(event) {
    //     setState(event.target.value);
    // }

    function changeLoanPurpose(event){
        setLoanPurpose(event.target.value);
        
    }

    function changeIsPrimaryResidence(event){
        setIsPrimaryResidence(event.target.value)
    }

    function changePhoneNumber(event) {
        setPhoneNumber(event.target.value);
    }    
    
    function changeEmail(event) {
        setEmail(event.target.value);
    }
    
    function changeLoanAmount(event) {
        setLoanAmount(event.target.value);
    }
    
    function changeNotes(event) {
        setNotes(event.target.value);
    }

    async function handleClick(event){
        let errors = []
        if (firstName == ""){
            errors.push('Enter your first name')
        } 
        if (lastName == ""){
            errors.push('Enter your last name')
        } 
        let phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        if (!phoneRegex.test(phoneNumber)){
            errors.push('Enter a valid phone number')
        }
        let emailRegex = /^\S+@\S+\.\S+$/
        if (!emailRegex.test(email)){
            errors.push('Enter a valid email address')
        }
        // if (state == ""){
        //     errors.push('State must be filled in')
        // }

        if (errors.length != 0){
            alert(errors.join('\n'));
            return;
        }

        try {
            const data = {
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber,
                addressOne: addressOne, 
                addressTwo: addressTwo, 
                city: city,
                state: state, // Fix: added missing colon
                zipCode: zipCode, 
                email: email,
                loanAmount: loanAmount,
                notes: notes
            };

            // console.log(data);
            const response = await fetch('/api/submit',{
              method: 'Post',
              headers: {
                'Content-Type': 'application/json'
                },
              body: JSON.stringify(data),
            });
            const result = await response.json();
            console.log(result);

            // show success message for some seconds then

            navigate('/')

          } catch (error) {
            console.error("Error:", error);
            alert(error);
          }
        
    }

    return (
        <Container maxWidth="xl" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}> 
            <Typography sx={{fontFamily: 'sans-serif', color: '#000000', fontWeight: 'bold', marginTop: '30px', textAlign: 'center', width:'100%'}} variant='h2' >Get Approved Today!</Typography>
            <Typography sx={{fontFamily: 'sans-serif', color: '#2a2d30', marginTop: '5px',textAlign: 'center', width: '65%'}} variant='h5'>Please fill out the below Quick Contact Form to get started. </Typography>
            <Typography sx={{fontFamily: 'sans-serif', color: '#2a2d30', marginBottom: '40px', textAlign: 'center', width: '65%'}} variant='h5'>Once received we will get in touch regarding next steps! </Typography>


            <FormGroup sx={{display: 'inherit', flexDirection: 'row', justifyContent: 'center', }}>
                <TextField value={firstName} id="firstName" label="First Name" variant="outlined" placeholder='Joseph' onChange={changeFirstName} sx={{width: {xs: '250px', md:'350px', lg: '500px'}, mr: '10px'}} />
                <TextField required value={lastName} id="lastName" label="Last Name" variant="outlined" placeholder='Smith' onChange={changeLastName} sx={{width: {xs: '250px', md:'350px', lg: '500px'}, ml: '10px'}}/>
            </FormGroup>

            <FormGroup sx={{mt: '20px', display: 'inherit', flexDirection: 'row', justifyContent: 'center', }}>
                <TextField required id="phone" value={phoneNumber} label="Phone Number" variant="outlined" placeholder='123-456-7890' onChange={changePhoneNumber} sx={{width: {xs: '250px', md:'350px', lg: '500px'}, mr: '10px'}}/>
                <TextField required id="email" value={email} label="Email" variant="outlined" placeholder='joey@gmail.com' onChange={changeEmail} sx={{width: {xs: '250px', md:'350px', lg: '500px'}, ml: '10px'}}/>
            </FormGroup>

            <Typography variant='body' sx={{fontWeight: '600', fontFamily: 'sans-serif', color: 'black', marginTop: '50px'}}>Please enter the property address associated with the loan request</Typography>
            <FormGroup sx={{mt: '10px', display: 'inherit', flexDirection: 'row', justifyContent: 'center', }}>
                <TextField required id="addressOne" value={addressOne} label="Street Address" variant="outlined" placeholder='123 Example Ave' onChange={changeAddressOne} sx={{width: {xs: '250px', md:'350px', lg: '500px'}, mr: '10px'}}/>
                <TextField id="addressTwo" value={addressTwo} label="Street Address Line 2" variant="outlined" placeholder='Apt 5B' onChange={changeAddressTwo} sx={{width: {xs: '250px', md:'350px', lg: '500px'}, ml: '10px'}}/>
            </FormGroup>

            <FormGroup sx={{mt: '20px', display: 'inherit', flexDirection: 'row', justifyContent: 'center', }}>
                <TextField required id="city" value={city} label="City" variant="outlined" placeholder='Miami' onChange={changeCity} sx={{width: {xs: '250px', md:'350px', lg: '500px'}, mr: '10px'}}/>
                <TextField required id="state" value={state} label="State" variant="outlined" placeholder='Florida' onChange={changeState} sx={{width: {xs: '250px', md:'350px', lg: '500px'}, ml: '10px'}}/>
            </FormGroup>

            <FormGroup sx={{mt: '20px', display: 'inherit', flexDirection: 'row', justifyContent: 'center', }}>
                <TextField required id="zipCode" value={city} label="Zip Code" variant="outlined" placeholder='Miami' onChange={changeZipCode} sx={{width: {xs: '250px', md:'350px', lg: '500px'}, mr: '10px'}}/>
                <TextField disabled id="country" value={country} label="Country" variant="outlined" sx={{width: {xs: '250px', md:'350px', lg: '500px'}, ml: '10px', "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"},}}/>
            </FormGroup>

            <FormGroup sx={{mt: '50px', display: 'inherit', flexDirection: 'column', justifyContent: 'left', }}>
                <InputLabel htmlFor="loanAmount" sx={{color: 'black', fontWeight: '500'}}>Amount of Loan Request</InputLabel>
                <TextField InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}} required id="loanAmount" value={city} variant="outlined" placeholder='50,000' onChange={changeLoanAmount} sx={{width: {xs: '250px',sm: '520px', md:'720px', lg: '1020px'}}}/>
            </FormGroup >

            <FormGroup sx={{mt: '10px', display: 'inherit', flexDirection: 'row', justifyContent: 'left', }}> 
                <Box sx={{mr: '10px', }}> 
                    <InputLabel htmlFor="loantype" sx={{color: 'black', fontWeight: '500'}}>Purpose of Loan</InputLabel>
                    <Select required id="loantype" value={loanPurpose} label='' onChange={changeLoanPurpose} sx={{width: {xs: '250px', md:'350px', lg: '500px'}}}>
                        <MenuItem value={'refinance'}>Refinance</MenuItem>
                        <MenuItem value={'purchase'}>Purchase</MenuItem>
                    </Select>

                </Box>
               
                <Box sx={{ml: '10px', }}> 
                    <InputLabel htmlFor="isResidence" sx={{color: 'black', fontWeight: '500'}}>Will this property be your permanent residence? </InputLabel>
                    <Select required id="isResidence" value={isPrimaryResidence} label='' onChange={changeIsPrimaryResidence} sx={{width: {xs: '250px', md:'350px', lg: '500px'}}}>
                        <MenuItem value={'yes'}>Yes</MenuItem>
                        <MenuItem value={'no'}>No</MenuItem>
                    </Select>
                </Box>
            </FormGroup>
            
            <FormGroup sx={{mt: '50px', display: 'inherit', flexDirection: 'row', justifyContent: 'center', }}> 
                <TextField multiline rows={3} value={notes} id="notes" label="Notes" variant="outlined" placeholder='...' onChange={changeNotes} sx={{width: {xs: '250px',sm: '520px',  md:'720px', lg: '1020px'}}}/>
            </FormGroup>

            <Box sx={{mt: '10px', display: 'inherit', flexDirection: 'column', justifyContent: 'left', width: {xs: '250px',sm: '520px',  md:'720px', lg: '1020px'}}}>
                <Button sx={{mt: '10px', border: '2px solid #000000',color: 'white', fontWeight: 'bold', background: '#000000', textShadow: '0px 0px 4px #000000'}} onClick={handleClick}>Submit</Button> 
            </Box>
                    {/* <Box
                        sx={{
                            background: 'red'
                        }}
                        noValidate
                        autoComplete="off"
                    >
                       
                                            </Box>
                    <Box
                        component='form'
                        sx={{
                            '& > :not(style)': { m: 2, width: '30ch' },
                            
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField id="companyName" value={companyName} label="Company Name" variant="outlined" placeholder='Standard Corp.' onChange={changeCompanyName}/>
                        <TextField id="stateLocation" value={state} label="State" variant="outlined" placeholder='Florida' onChange={changeState}/>
                    </Box>
                    <Box
                        component='form'
                        sx={{
                            '& > :not(style)': { m: 2, width: '30ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        
                        <TextField required id="phone" value={phoneNumber} label="Phone Number" variant="outlined" placeholder='123-456-7890' onChange={changePhoneNumber}/>
                        <TextField required id="email" value={email} label="Email" variant="outlined" placeholder='joey@gmail.com' onChange={changeEmail}/>
                    </Box>
                    <Box
                        component='form'
                        sx={{
                            '& > :not(style)': { m: 2, width: '30ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        
                        <TextField id="creditScore" value={creditScore} label="Credit Score" variant="outlined" placeholder='700' onChange={changeCreditScore}/>
                        <TextField id="amountLoan" value={loanAmount} label="Loan Amount" variant="outlined" placeholder='$1,000,000' onChange={changeLoanAmount}/>
                    </Box>
                    <Box
                        sx={{
                            '& > :not(style)': { m: 2, width: '50ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField multiline rows={3} value={notes} id="notes" label="Notes" variant="outlined" placeholder='...' onChange={changeNotes} sx={{width: '90% !important'}}/>
                    </Box>
                    <Box> 
                        
                    </Box>
                    <Button sx={{marginLeft: '10px', border: '2px solid #000000',color: 'white', fontWeight: 'bold', background: '#000000', textShadow: '0px 0px 4px #000000'}} onClick={handleClick}>Submit</Button> */}
                
        </Container> 
        
    );
}