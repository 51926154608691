import * as React from 'react';


import { Button, CardActions, Table, TableRow, TableCell,TableBody, Grid, Typography, Card, Box, CardContent, CardMedia, Container } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

// change for production
// import about1 from '../static/about1.png';
//import about2 from '../static/about2.png';
// import about3 from '../static/about3.png';
// import about4 from '../static/about4.png';
// import about5 from '../static/about5.png';
import about6 from '../static/about6.png';
// import about7 from '../static/about7.png';
import aboutpage1 from '../static/aboutpage1.jpg'

const SmallCell = styled(TableCell)({
    padding: '2px',
    fontFamily: 'sans-serif',
    fontSize: '18px',
    fontWeight: '500',
})

const rows = [
    ['Experience Level', 'Any'], 
    ['Credit Score', '600'], 
    ['Interest Rate', '9%-13%'], 
    ['Points', '0%-2%'], 
    ['Eligible for a 0-points option?', 'Yes'], 
    ['Advance Rate on Rehab or New Construction','10%'], 
    ['Advance Rate on Current Value','10%'], 
    ['Loan % to After Rehab Value', '75%'], 
    ['Property Types', 'Single Family, Multi-Family, Mixed Use'], 
    ['Prepayment Penalty', 'No'], ['Extension Available', 'Yes'], 
    ['Cross Colateral / Blanket Loans', 'Yes'], 
    ['Loan Size', '$100,000-$5 million'], 
    ['Loan Terms', '3 months - 2 years'], 
]

const extraRows = [
    ['Income Verification', 'yes'],
]


function App() {
    const navigate = useNavigate();
  // const [message, setMessage] = useState("");

  // useEffect(() => {
  //   getMessage();
  // }, []);

  // async function getMessage() {
  //   const result = await fetch("/api/message");
  //   const json = await result.json();

  //   setMessage(json);
  // }
  


  return (<>
    {/* <Box sx={{}}> 
        <Grid item sm={12} sx={{display: {xs: 'none', sm: 'none', md:'flex'}, justifyContent: 'center', alignItems:'center',  height: { md: '400px'}, backgroundImage:"url('"+aboutpage1+"')", backgroundAttachment: 'contain', backgroundSize: {md: '100% 400px'}, backgroundRepeat: 'no-repeat', }}>
            <Typography variant='h1' fontFamily={'sans-serif'} fontWeight={'bold'} sx={{textAlign: 'center', color: '#ffffff', textShadow: '4px 4px 4px #000000'}}>Bridge Loans</Typography>
        </Grid>
    </Box> */}
    <Container sx={{ display: 'flex', flexDirection: 'column', position: 'relative', justifyContent: 'space-evenly'}}> 
        <Grid container spacing={2} sx={{ marginTop: '20px',  display: 'flex', flexDirection: 'row', height: '100%', maxHeight: '2000px', width: '100%', justifyContent: 'center'}}>
            <Grid item xs={12}  sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
                <Grid container spacing={1} sx={{display: 'flex', flexDirection: 'row',marginBottom: '5px'}}>
                    <Grid item sx={{width: '125px', display: {xs: 'none', sm: 'flex'}}}>
                        <Card raised sx={{width: '100%', height: '125px'}}>
                            <CardMedia src={about6} // fix for production
                            component={'img'} sx={{objectFit: 'contain'}}>

                            </CardMedia>
                        </Card>
                    </Grid>
                    <Grid item xs>
                        <Card raised sx={{width: '100%', height: '125px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <CardContent>
                                <Typography variant='h3' fontFamily={'sans-serif'} fontWeight={'bold'} sx={{}}>Bridge Loans</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Card raised sx={{width: '100%', minHeight: '150px', overflow: "auto"}}>
                    <CardContent>
                        <Typography variant='body1' fontFamily={'sans-serif'} sx={{fontSize: '18px'}}>
                            Navigate seamless transitions in real estate with MK Lending's Bridge Loan
                            Program. A bridge loan serves as a strategic financing solution, providing short-
                            term capital to bridge the gap between the purchase of a new property and the
                            sale of an existing one. As a leading player in real estate financing, MK Lending 
                            offers competitive rates, expedited approvals, and personalized terms to
                            ensure a smooth and timely transition for our clients. Whether you're
                            upgrading, downsizing, or navigating complex real estate transactions, trust
                            MK Lending to be your reliable partner in bridging the financial gap.
                        </Typography>
                    </CardContent>
                </Card>
                
               
                
            </Grid>
            <Grid item xs={12}>
                <Card raised sx={{width: '100%', height: '100%'}}>
                    <CardContent sx={{height: '100%'}}>
                        <Table >
                            <TableBody sx={{padding: '0px'}}>
                                {[...rows].map((row) => (
                                    <TableRow key={row[0]+row[1]} >
                                        <SmallCell align='left'>{row[0]}</SmallCell>
                                        <SmallCell align='left'>{row[1]}</SmallCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Card raised sx={{background: 'blue', width: '100%', height: '100px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <CardContent sx={{ml: 4, }}>
                        <Typography variant='h4' fontWeight={'bold'} color={'white'} >Free Loan Application Process</Typography>
                    </CardContent>
                    <CardActions>
                        <Button sx={{ mr: 4, color: 'blue',  background: 'white', display: 'block', border: '2px solid white', textShadow: '0px 0px 8px white',  fontWeight: '600'}} onClick={(e) => {navigate('/apply')}}>Apply Now</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </Container>
  </>)
}

export default App;